import frontBrown from '../../assets/front-brown.JPG';
import backBrown from '../../assets/back-brown.JPG';
import leftBrown from '../../assets/left-brown.JPG';
import rightBrown from '../../assets/right-brown.JPG';
import frontBlue from '../../assets/front-blue.JPG';
import backBlue from '../../assets/back-blue.JPG';
import leftBlue from '../../assets/left-blue.JPG';
import rightBlue from '../../assets/right-blue.JPG';
import frontred from '../../assets/front-red.JPG';
import backred from '../../assets/back-red.JPG';
import leftred from '../../assets/left-red.JPG';
import frontgreen from '../../assets/front-green.JPG';
import backgreen from '../../assets/back-green.JPG';
import leftgreen from '../../assets/left-green.JPG';

export interface ProductInterface {
    Title: string;
    Description: string;
    MainImage: string;
    ImageList: string[];
}
export const allProducts: ProductInterface[] = [
    {
        Title: 'True Cinnamon',
        Description: 'Embrace the essence of style and symbolism with the Elegant Harmony Cap, beautifully embroidered to capture the spirit of devotion and beauty. This cap features a crisp, cream-colored canvas adorned with a vibrant red rose and an intricately stitched letter "M," symbolizing both mystery and majesty. The front showcases the rose in full bloom, an emblem of love and passion, while the sides carry the powerful words "Loyalty Family Hustle," reminding the wearer of the values that bind and strengthen. The back, in a contrast of solid tan, ensures a snug fit with its adjustable strap, making it a versatile accessory for any outfit. Perfect for those who carry elegance and a sense of deep-rooted values in their everyday hustle.',
        MainImage: frontBrown,
        ImageList: [frontBrown, leftBrown, backBrown, rightBrown]
    },
    {
        Title: 'Turquoise Gem',
        Description: `The Vibrant Spirit Cap is a statement piece that blends classic charm with a pop of color. Featuring a serene cream base, the cap is adorned with a bold red rose and a delicately embroidered 'M' for a touch of personalization. The front of the cap showcases the rose, a universal symbol of love and courage, beautifully contrasted against the vibrant blue brim that brings a youthful energy to the design. On the sides, the words "Loyalty Family Hustle" are stitched in a striking red, reinforcing a message of commitment and community. The back features an adjustable blue strap, ensuring a perfect fit while adding a splash of color. This cap is ideal for those who value a mix of tradition and modernity in their fashion choices.`,
        MainImage: frontBlue,
        ImageList: [frontBlue, leftBlue, backBlue, rightBlue]
    },
    {
        Title: `Ricch Red`,
        Description: `Step out in confidence with the Passionate Pioneer Cap, a bold blend of style and sentiment. This cap features a classic cream top paired with a fiery red brim that adds a dash of audacity to your ensemble. The centerpiece is a beautifully embroidered red rose alongside a shimmering gold ‘M’, signifying mystery and allure. On the side, the cap carries the motto “Loyalty is Law”, embodying a deep-seated sense of fidelity and unity. The back, designed with a contrasting red strap, ensures a comfortable, custom fit, making it perfect for both casual and stylish occasions. Ideal for those who carry a fiery spirit and a loyal heart.`,
        MainImage: frontred,
        ImageList: [frontred, leftred, backred, leftred]
    },
    {
         Title: `Only Olive`,
        Description: `The Nature’s Embrace Cap is a perfect blend of outdoor inspiration and urban style. This cap features a soothing cream body paired with an earthy green brim that reflects the hues of nature. The front is elegantly adorned with a striking red rose intertwined with the metallic sheen of an embroidered ‘M’, symbolizing both strength and beauty. On the sides, the words “Loyalty Family Hustle” are proudly displayed, asserting values of dedication and unity. The back offers a practical adjustable green strap for a custom fit, ensuring comfort and style go hand-in-hand. Ideal for those who appreciate a touch of nature in their daily life and value deep, meaningful connections.`,
        MainImage: frontgreen,
        ImageList: [frontgreen, leftgreen, backgreen, leftgreen]
    }



]