import React from "react";

const PurchaseInfo = () => {
    const email ='family@theplayamafia.com'
    const text = '(972) 903 - 2125'
    return (
        <div>
            <div className="w-full border-t border-gray-300"></div>
            <p className="text-center font-serif py-12">Please Contact Us to Purchase.</p>
            <div className="flex flex-col align-center text-center">
                <a href={`mailto:${email}`}  className="font-serif text-start text-white rounded-md p-4 bg-black hover:cursor-pointer hover:text-black hover:bg-white text-l mb-2" >Email Us ~ {email.toUpperCase()}</a>
                <a href={`sms:${text}`} className="font-serif text-start hover:cursor-pointer rounded-md p-4 text-white bg-black hover:text-black hover:bg-white text-l mb-2" >Text Us ~ <span className="font-sans">{text}</span></a>
            </div>
        </div>
    )
}

export default PurchaseInfo;