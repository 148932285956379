import React from 'react'

interface ImageZoomProps {
    image: string,
    alt: string,
}

const ImageZoom: React.FC<ImageZoomProps> = ({image, alt}) => {
    return (
        <div className="relative overflow-hidden w-full h-full">
            <img src={image} alt={alt} className='w-full h-full object-cover transition-transform duration-300 ease-in-out hover:scale-150' />
        </div>
    )
}
export default ImageZoom 