import React from "react";
import logo from '../assets/logo.png'
import Menu from "../Menu";
import { titles, descriptions } from "./About-Information";
import dallas from '../../assets/dallasSkyline.jpg'
import { Parallax } from "react-parallax";
import {ReactComponent as Star} from '../../assets/star.svg';
import teensBuilding from '../../assets/teensBuilding.png';
import chris from '../../assets/chris.png';
import dre from '../../assets/dre.png';
import RunningScript from "../General/RunningScript";
import HomeButton from "../General/HomeButton";
import { useNavigate } from "react-router-dom";


const About = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Menu/>
            {/* This is part 1 */}
            <HomeButton />
            <section className=" py-24"> 
                <p className="my-24 text-white text-center text-xl  md:text-6xl italic font-serif">{titles['part1']}</p>
                <Parallax bgImage={dallas} strength={300} style={{}}>
                    <div className="h-96"></div>
                </Parallax>
                <div className="flex pt-12 flex-col md:flex-row items-center justify-evenly">
                    <p className="text-white max-w-xl flex-1 text-center md:text-left text-lg md:text-3xl leading-loose font-sans font-light ">{descriptions['part1']}</p>
                    {/* <div className="animate-rotate"> */}
                    <Star className="w-10 h-10 md:w-80 md:h-80"  />
                    {/* </div> */}
                </div>
            </section>
            {/* this is part 2 */}
            <section className="bg-white py-24">
                    <p className="mt-24 text-center font-serif italic md:text-6xl">{titles['part2']}</p>
                    <div className="flex flex-wrap flex-row justify-around items-center mt-8">
                        <img className="rounded-full h-60vh" src={teensBuilding} alt="" />
                        <p className="font-sans text:lg text-center md:text-3xl mt-5 leading-loose md:w-50vw font-light md:text-right">{descriptions['part2']} <br /> <span className="font-medium italic text-5xl">PURSUIT OF SUCCESS.</span> </p>
                    </div>
            </section>

            {/* This is part 3 */}
            <section className="pt-24">
                <p className="text-white italic md:text-4xl text-center font-serif">{titles['part3']}</p>
                <div className="flex flex-col flex-wrap py-24 items-center">
                    <div className="grid my-12 md:gap-24 md:grid-cols-2 items-center justify-center px-12 justify-items-center">
                        <p className="text-white text-2xl text-left font-sans">{descriptions['part31']}</p>
                        <div className="py-12">
                            <img src={dre} alt="picture-of-dre" className="" />
                            <p className="text-white align-middle text-center font-great-vibes text-xl lg:text-4xl">Devin Young</p>
                        </div>
                    </div>
                    <div className="grid my-12 md:gap-24 md:grid-cols-2 items-center justify-center px-12 justify-items-center">
                        <p className="block md:hidden text-white text-2xl text-right font-sans">{descriptions['part32']}</p>
                        <div className="py-12">
                            <img src={chris} alt="picture-of-chris" className="" />
                            <p className="text-white align-middle text-center font-caveat text-xl lg:text-4xl">Christopher Johnson</p>
                        </div>
                        <p className="hidden md:block text-white text-2xl text-right font-sans">{descriptions['part32']}</p>
                    </div>
                </div>
            </section>
            {/* THIS is part 4 */}
            <section className="bg-white pt-8 pb-12">
                <p className="mt-24 text-center font-serif italic md:text-6xl">{titles['part4']}</p>
                <p className="font-sans my-12 text:lg text-center md:text-3xl px-12 mt-5 leading-loose font-light">{descriptions['part4']}</p>
                <RunningScript/>
                <div className="flex justify-center space-x-12">
					<p className="font-serif text-start text-white rounded-md p-4 bg-black hover:cursor-pointer hover:text-black hover:bg-white text-l mb-2" onClick={() => navigate('/4sale')}>Purchase.</p>
					<p className="font-serif text-start hover:cursor-pointer rounded-md p-4 text-white bg-black hover:text-black hover:bg-white text-l mb-2" onClick={() => window.open('https://www.instagram.com/theplayamafia/')}>Follow Us.</p>
                </div>
            </section>



        </div>
    )
}

export default About