import React from 'react';

const RunningScript = () => {
    return (
        <div className="overflow-hidden bg-black py-12 mb-12">
            <div className="animate-marquee font-anton whitespace-nowrap">
                <span className="text-yellow-400 text-xl md:text-8xl  tracking-widest">THE WORLD IS YOURS...</span>
            </div>
        </div>
    )
}

export default RunningScript;