import React from 'react'
import { useNavigate } from 'react-router-dom';
import { FaHome } from 'react-icons/fa';

const HomeButton = () => {
	const navigate = useNavigate();
    return (
        <p onClick={() => navigate('/')}  className="fixed bottom-5 z-50 right-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full">
          <FaHome/> 
        </p>
    )
}

export default HomeButton;