import React, {useState, useEffect} from 'react';
import logo from '../assets/logo1.png';
import { useNavigate } from 'react-router-dom';

const Home = () => {
	const [time, setTime] = useState(new Date());
	const navigate = useNavigate();

	useEffect(() => {
		const timerId = setInterval(() => {
			setTime(new Date());
		}, 1000)
		return () => clearInterval(timerId);
	}, []);

	return(
		<div className="flex items-center justify-center h-screen bg-white text-coral">
			<div className="text-center">
				<img src={logo} alt='logo' className='hover:cursor-pointer mx-auto mb-4 w-full h-full'/>
				<p className='font-serif mb-3'>{`${time.toLocaleDateString()}   ${time.toLocaleTimeString('en-GB')}`}</p>
				<div className='flex justify-center items-center flex-col mt-8'>
					<p className="font-serif text-start hover:cursor-pointer hover:text-black hover:bg-white text-l mb-2" onClick={() => navigate('4sale')}>4Sale</p>
					<p className="font-serif text-start hover:cursor-pointer hover:text-black hover:bg-white text-l mb-2" onClick={() => window.open('https://www.instagram.com/theplayamafia/')}>Connect</p>
					<p className="font-serif text-start hover:cursor-pointer hover:text-black hover:bg-white text-l mb-2" onClick={() => navigate('About')}>About</p>
				</div>
			</div>
		</div>
		)
	}

export default Home;