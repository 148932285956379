export const descriptions = {
    part1: `Growing up in Dallas, loyalty was everything. Family, friends, my people – it was the foundation.  But in those streets, there was also the allure of power, respect, and yeah, even the attention of the ladies. We all saw it in those classic gangster movies, more so the environment we grew up in – the magnetism of a true boss. PlayaMafia was born from that fire. A vision to build something real, something that channels the spirit of a young hustler with big dreams.`,
    part2: `PlayaMafia isn't just a brand; it's a mindset. It's about owning your hustle, walking with confidence, and never forgetting where you came from. Our products are designed for the man and also the woman who demands attention from everyone in the room. We offer the gear, the style, and the attitude that speaks to ambition, loyalty, and the relentless`,
    part31: `PlayaMafia is about more than just looking good; it's about living the life you want. We're a community of brothers and sisters, united by our drive, our passion, and our unwavering commitment to family.`,
    part32: `When you rock our gear, you're not just making a statement, you're joining a movement. A movement built on the values of loyalty, ambition, and a relentless pursuit of the finer things in life.`,
    part4: `Every king needs his crown, every boss needs his empire. PlayaMafia is your invitation to build yours. Our products aren't just clothes, they're symbols of your hustle, your determination, and your unwavering belief in yourself. This is your chance to write your own story, to become the legend you were born to be. This ain't just fashion, it's family. Welcome to PlayaMafia.`,
}

export const titles = {
    part1: `Part 1: Born in the Streets, Built on Dreams`,
    part2: `Part 2: Power, Respect, and the Art of Attraction`,
    part3: `Part 3: More Than Just Threads – It's a Lifestyle`,
    part4: `Part 4: Your Legacy Starts Here`,
}