import React, {useState, useEffect} from "react";
import logo from '../assets/logo1.png'
import { useNavigate } from "react-router-dom";

const Menu = () => {
	const [time, setTime] = useState(new Date());
	const navigate = useNavigate();

    const [isShrunk, setIsShrunk] = useState(false);

	useEffect(() => {
		const timerId = setInterval(() => {
			setTime(new Date());
		}, 1000)
		return () => clearInterval(timerId);
	}, []);
    useEffect(() => {
        const handleScroll = () => {
            const shouldBeShrunk = window.scrollY > 100;
            setIsShrunk(shouldBeShrunk);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div>
            <div className="text-center top-10">
				<img onClick={() => navigate('/')} src={logo} alt='logo' className='hover:cursor-pointer mx-auto py-6 w-34 h-28'/>
				{/* <h1 className="font-serif hover:cursor-pointer text-4xl font-bold mb-2">PlayaMafia</h1> */}
				<p className='font-serif text-l mb-3 text-coral'>{`${time.toLocaleDateString()}   ${time.toLocaleTimeString('en-GB')}`}</p>
			</div>
            {/* <div className={`text-center transition-all items-center justify-center h-screen bg-black duration-300 ease-in-out w-full ${isShrunk ? 'h-12 fixed bg-blue-500' : 'h-48 bg-blue-300'}`}>
                <div className={`max-w-4xl mx-auto flex items-center justify-between p-4 ${isShrunk ? 'text-sm' : 'text-xl'}`}>
                    <img onClick={() => navigate('/')} src={logo} alt='logo' className={` hover:cursor-pointer transition-all duration-300 ease-in-out ${isShrunk ? 'w-34 h-28' : 'w-64 h-48'}`}/>
                    <p className={`text-red-900 max-w-4xl mx-auto p-4 ${isShrunk ? 'text-sm' : 'text-xl'}`}>{`${time.toLocaleDateString()}   ${time.toLocaleTimeString('en-GB')}`}</p>
                </div>
			</div> */}
        </div>
    )
}

export default Menu;