import React, {useEffect, useRef} from "react";


interface DialogBoxProp {
    isOpen: boolean;
    onClose: () => void;
    title: string;
    children: React.ReactNode;
}

const DialogBox: React.FC<DialogBoxProp> = ({isOpen, onClose, title, children}) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div ref={ref} style={{maxHeight: '650px'}} className="bg-white p-4 overflow-y-auto rounded-lg shadow-lg max-w-md w-full">
                <div className="flex justify-end items-end">
                    <div className="w-12">
                        <button onClick={onClose} className="p-2 fixed z-50 bg-yellow-500 rounded-full hover:bg-gray-200">
                            <p className="font-serif">&nbsp;X&nbsp;</p>
                        </button>
                    </div>
                </div>
                <div className="flex justify-center item-center">
                    <h2 className="text-lg font-serif text-center font-bold py-12">{title}</h2>
                </div>
                <div className="mt-4">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DialogBox;