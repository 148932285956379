import React, {useState, useEffect} from 'react'
import { ProductInterface } from './allProducts';
import DialogBox from '../General/DialogBox';
import ImageZoom from '../General/ImageZoom';
import PurchaseInfo from './PurchaseInfo';

interface ProductInformation {
    productInfo: ProductInterface
}

const ProductCard: React.FC<ProductInformation> = ({productInfo}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imageIndex, setImageIndex] = useState(0);
    const [fade, setFade] = useState(true);
    const [selectedImage, setSelectedImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(false);
            setTimeout(() => {
                setImageIndex(index => (index + 1) % 4);
                setFade(true);
            }, 600)
        }, 4000);
        return () => clearInterval(interval);
    }, [])
    return (
        <div className=' m-12 flex flex-col items-center justify-around py-8 px-2'>
            <div className='hover:cursor-pointer w-screen md:w-50vw xl:w-35vw rounded-3xl drop-shadow-sm bg-white py-10 flex flex-col items-center' onClick={() => setIsOpen(true)}>
                <h2 className='font-serif font-semibold text-black my-10'>{productInfo.Title}</h2>
                <div className='relative bg-white h-96 w-96 items-center overflow-hidden'>
                    {productInfo.ImageList.map((image, index) => (
                        <img src={image} key={image} alt={`slideshow-image${index}`} style={{top: 0, left: 0}} className={`absolute object-cover h-full w-full transition-opacity ease-in-out duration-500 ${imageIndex === index && fade ? 'opacity-100' : 'opacity-0'} ${imageIndex === index ? 'z-10' : 'z-0'}`} />
                    ))}
                </div>
            </div>
            <DialogBox title={productInfo.Title} isOpen={isOpen} onClose={() => setIsOpen(false)} >
                {/* INSIDE DIALOG BOX */}
                <div className="flex flex-col justify-center">
                    <ImageZoom image={productInfo.ImageList[selectedImage]} alt={productInfo.Title} />
                    <div className="flex flex-row justify-around">
                        {productInfo.ImageList.map((image, index) => (
                            <img key={`cap-${index}`} className='h-24 w-24 hover:border-2 hover:cursor-pointer' onClick={() => setSelectedImage(index)}  src={image} alt={productInfo.Title+index+'-small'} />
                        ))}
                    </div>
                    <div>
                        <p className='text-center rounded-lg bg-fuchsia-200'>scroll to see more</p>
                    </div>
                    <p className="font-sans py-2 my-8">{productInfo.Description}</p>
                    <PurchaseInfo />
                </div>
            </DialogBox> 
        </div>
    )
}

export default ProductCard;