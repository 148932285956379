import React from "react";
import Menu from "../Menu";
import ProductCard from "./ProductCard";
import { allProducts, ProductInterface } from "./allProducts";
import HomeButton from "../General/HomeButton";


const Products = () => {
    return(
        <div>
            <Menu/>
            <HomeButton />
            <div className="flex flex-col md:flex-wrap xl:flex-row justify-around">
                {allProducts.map((product, index) => (
                    <ProductCard key={product.Title + index} productInfo={product}/>
                ))}
            </div>
        </div>
    )
};

export default Products;