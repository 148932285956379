import React from 'react';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/Home';
import About from './Components/About/About';
import Products from './Components/Products/Products';
import NotFound from './Components/NotFound';
import ScrollToTop from './Components/General/ScrollToTop';

function App() {
  return (
    <div >
      <Router>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/about' element={<About/>}/>
          <Route path='/4sale' element={<Products/>}/>
          <Route path='*' element={<Home/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
